<template>
    <div>
        <List
            :columns="columns"
            :get_table_list="get_table_list"
            @handle_details_click="handle_details_click"
            :form_data_seo.sync="form_data_seo"
			:submit_preprocessing="submit_preprocessing"
        />
        <RightDrawer :visible.sync="visible">
            <template slot="content">
                <ApprovalHeader :details="details"></ApprovalHeader>
                <a-divider />
                <a-descriptions>
                    <a-descriptions-item :span="3" label="所属部门">{{details.department}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="岗位职务">{{details.role}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="审批编号">{{details.id}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="离职类型">{{details.type}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="入职时间">{{details.start_time}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="离职日期">{{details.end_time}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="请假事由">{{details.desc}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="图片">
                        <img class="leave_img" src alt />
                    </a-descriptions-item>
                </a-descriptions>
                <a-divider />
                <div class="drawer_title">审批进度</div>
                <ApprovalSpeed :list="details.approver"></ApprovalSpeed>
                <div class="drawer_title" v-if="details.cclist.length">抄送</div>
                <ApprovalCc :list="details.cclist"></ApprovalCc>
            </template>
        </RightDrawer>
    </div>
</template>

<script>
import List from './components/list'
import { quitList, quitInfo, getType } from '@/api/approval'
import RightDrawer from "@/components/RightDrawer";
import ApprovalSpeed from "@/components/ApprovalSpeed";
import ApprovalCc from "@/components/ApprovalCc";
import ApprovalHeader from "@/components/ApprovalHeader";

const columns = [
    {
        title: "审批编号",
        dataIndex: "id",
    },
    {
        title: "标题",
        dataIndex: "title",
    },
    {
        title: "部门",
        dataIndex: "path_name",
    },
    {
        title: "离职类型",
        dataIndex: "name",
    },
    {
        title: "入职时间",
        dataIndex: "start_time",
    },
    {
        title: "离职日期",
        dataIndex: "end_time",
    },
    {
        title: "离职事由",
        dataIndex: "desc",
    },
    {
        title: "审批状态",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status"
        }
    },
    {
        title: "操作",
        width: 150,
        scopedSlots: {
            customRender: "operation"
        }
    },
];

export default {
    components: {
        List,
        RightDrawer,
        ApprovalSpeed,
        ApprovalCc,
        ApprovalHeader
    },
    data() {
        return {
			columns,
			submit_preprocessing:{
				array_to_string: ['department']
			},
            get_table_list: quitList,
            visible: false,
            details: {
                cclist: []
            },
            form_data_seo: {
                ...this.$config.form_data_seo,
                list: [
                    {
                        type: 'select',
                        name: 'type',
                        title: '离职类型',
                        list: [

                        ]
                    }
                ]
            }
        }
    },
    created() {
        getType({
            data: {
                type: 'quit'
            }
        }).then(res => {
            let type_list = this.$method.to_form_list(res.data.list, 'id', 'name')
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'type', 'list', type_list)
        })
    },
    methods: {
        handle_details_click({ record }) {
            quitInfo({
                data: {
                    id: record.id
                }
            }).then(res => {
                let data = res.data;
                this.details = data;
                this.visible = true;
            })

        }
    }
}
</script>

<style lang="less" scoped>
.leave_img {
    width: 100px;
    height: 100px;
}
</style>